import { usePersistStringState } from "./usePersistStringState";

export function usePersistNewReliabilityReducer(reducer, initialState) {
  const [state, setState] = usePersistStringState(initialState, "newRel");

  const dispatch = action => {
    setState(() => reducer(state, action));
  };

  return [state, dispatch];
}
