import React from "react";
import { createContext, useContext } from "react";
import { initialPersistState, newReliabilityReducer } from "../reducer";
import { usePersistNewReliabilityReducer } from "./usePersistNewReliabilityReducer";

const NewReliabilityContext = createContext(null);

export function NewReliabityProvider({ children }) {
  const [
    newRelibility,
    newReliabilityDispatch
  ] = usePersistNewReliabilityReducer(
    newReliabilityReducer,
    initialPersistState
  );

  return (
    <NewReliabilityContext.Provider
      value={[newRelibility, newReliabilityDispatch]}
    >
      {children}
    </NewReliabilityContext.Provider>
  );
}

export function useNewReliabilityState() {
  return useContext(NewReliabilityContext);
}
