import { API_PATH } from "../../../commons/constants";
import fetchApi from "../../../commons/fetch-api";

export const readCertificate = accountId => async (dispatch, state) => {
  const pathRequest = API_PATH.endpoints.putReliability
    .replace(":accountId", accountId)
    .replace(":reliabilityId", "certificate");

  return await fetchApi(pathRequest, { method: "PUT" });
};
