import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppState, useQuery } from "../AppContext";
import { fetchLoggedUser } from "../../commons/logged-user/actions";
import { readCertificate } from "../acquire-reliability/certificate/actions";
import { useNewReliabilityState } from "../../commons/reliability-store/hooks/useNewReliabilityContext";
import { persistNewReliability } from "../../commons/reliability-store/actions";
import { changeLevelData } from "../home/actions";

const componentDidMount = (
  dispatch,
  state,
  newReliabilityDispatch,
  history
) => () => {
  if (window.location.hostname.startsWith("certificado.")) {
    var accountId = new URLSearchParams(window.location.search).get(
      "accountId"
    );
    if (accountId !== undefined && accountId !== null) {
      readCertificate(accountId)(dispatch, state).then(res => {
        changeLevelData({
          ...state.levelData,
          up: res?.hasLevelChanged,
          newReliability: res?.reliabilityId,
          level: res?.userLevel
        });

        if (res?.hasLevelChanged) {
          newReliabilityDispatch(persistNewReliability(res?.reliabilityId));
        } else {
          const replacedHostname = window.location.hostname.replace(
            "certificado.",
            ""
          );
          window.location.href = `https://${replacedHostname}`;
        }

        history.push("/");
      });
    }
  }
  dispatch(fetchLoggedUser());
};

function Init() {
  const [state, dispatch] = useAppState();
  const newReliabilityDispatch = useNewReliabilityState()[1];
  const history = useHistory();

  const paramObj = useQuery();
  const qpClientId = paramObj.get("client_id");
  if (qpClientId) {
    sessionStorage.setItem("clientUrlReliability", window.location.href);
  }

  useEffect(
    componentDidMount(dispatch, state, newReliabilityDispatch, history),
    []
  );

  return !state.logged_user.id && <span className="init__message" />;
}

export default Init;
