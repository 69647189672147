import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import AppConfiabilidades from "./components/layout/Main";
import Configurations from "./config";
import { AppStateProvider } from "./components/AppContext";
import { NewReliabityProvider } from "./commons/reliability-store/hooks/useNewReliabilityContext";

const initialState = Configurations.initialState;
const reducers = Configurations.reducers;
Configurations.configFetchApi();

function Index() {
  return (
    <Router>
      <AppStateProvider initialState={initialState} reducer={reducers}>
        <NewReliabityProvider>
          <AppConfiabilidades />
        </NewReliabityProvider>
      </AppStateProvider>
    </Router>
  );
}

ReactDOM.render(<Index />, document.getElementById("root"));
