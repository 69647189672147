import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppState } from "../../AppContext";
import { changeInfoTela } from "../../../../src/components/layout/actions";
import { putFederalEmployeePassword } from "./actions";
import {
  changeFormData,
  changeLevelData,
  reliabilityAcquired
} from "../../home/actions";
import { changeMessageDelay } from "../../layout/actions";
import { useNewReliabilityState } from "../../../commons/reliability-store/hooks/useNewReliabilityContext";
import { persistNewReliability } from "../../../commons/reliability-store/actions";

const componentDidMount = (dispatch, state) => () => {
  dispatch(
    changeInfoTela({
      title: "Obter Confiabilidade de Servidor Público",
      breadcrumbs: [
        { label: "Nível da conta", link: "/" },
        {
          label: "Aumentar nível da conta",
          link: "/quero-este-nivel?nivel=prata"
        },
        { label: "Obter Confiabilidade de Servidor Público" }
      ]
    })
  );
  return () => {
    dispatch(changeFormData({ questions: [], formData: {}, biovalid: {} }));
  };
};

function FederalEmployee() {
  const history = useHistory();
  const [state, dispatch] = useAppState();
  const newReliabilityDispatch = useNewReliabilityState()[1];
  const { formData } = state;

  useEffect(componentDidMount(dispatch, state), []);

  const validate = password => {
    if (password === undefined || password.length === 0) {
      dispatch(
        changeMessageDelay({ type: "ERROR", text: 'Preencha o campo "senha".' })
      );
      return;
    }
    putFederalEmployeePassword(password)(dispatch, state).then(res => {
      changeLevelData({
        ...state.levelData,
        up: res?.hasLevelChanged,
        newReliability: res?.reliabilityId,
        level: res?.userLevel
      });

      if (res?.hasLevelChanged) {
        newReliabilityDispatch(persistNewReliability(res?.reliabilityId));
      }

      dispatch(reliabilityAcquired(res.hasLevelChanged));
      history.push("/");
    });

    dispatch(componentDidMount(dispatch, state), []);
  };

  return (
    <>
      <span className="page-title">
        Obter Confiabilidade de Servidor Público
      </span>
      <p>
        Para obter a confiabilidade como Servidor Público é necessário que
        informe a senha de servidor do sistema SIGEPE.
      </p>
      <label htmlFor="password">Digite a senha do SIGEPE (SIGAC)</label>
      <input
        value={formData.password}
        type="password"
        placeholder="Digite a sua senha do Sigepe (campo obrigatório)"
        onChange={e => {
          dispatch(changeFormData({ ...formData, password: e.target.value }));
        }}
        autoFocus={true}
      />
      <div className="action-group">
        <div className="action-group-left">
          <Link className="action-tertiary" to="/quero-este-nivel?nivel=prata">
            Voltar
          </Link>
        </div>
        <div>
          <button
            className="action-primary"
            type="submit"
            onClick={() => validate(formData.password)}
          >
            Validar Senha
          </button>
        </div>
      </div>
    </>
  );
}

export default FederalEmployee;
