import {
  NEW_RELIABILITY_ACQUIRED,
  ERASE_PERSISTED_RELIABILITY
} from "./actions";

export function newReliabilityReducer(state, action) {
  switch (action.type) {
    case NEW_RELIABILITY_ACQUIRED: {
      return action.data;
    }

    case ERASE_PERSISTED_RELIABILITY: {
      return action.data;
    }

    default: {
      throw Error("[newReliabilityReducer] Unknown action: " + action.type);
    }
  }
}

export const initialPersistState = "";
