import { changeMessageDelay } from "../../layout/actions";
import { changeFormData } from "../../home/actions";
import { API_PATH } from "../../../commons/constants";
import { reliabilityAcquired } from "../../home/actions";
import fetchApi from "../../../commons/fetch-api";

export const putFederalEmployeePassword = password => async (
  dispatch,
  state
) => {
  const pathRequest = API_PATH.endpoints.putReliability
    .replace(":accountId", state.logged_user.id)
    .replace(":reliabilityId", "federal-employee");

  return await fetchApi(pathRequest, {
    method: "PUT",
    body: JSON.stringify({ password: password })
  });
};
