import "./style.css";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppState, useQuery } from "../../AppContext";
import { changeInfoTela } from "../../layout/actions";
import fetchApi from "../../../commons/fetch-api";
import { useNewReliabilityState } from "../../../commons/reliability-store/hooks/useNewReliabilityContext";
import { persistNewReliability } from "../../../commons/reliability-store/actions";
import { changeLevelData } from "../../home/actions";

function AccreditedBanksRedirectEndpoint() {
  const [state, dispatch] = useAppState();
  const newReliabilityDispatch = useNewReliabilityState()[1];
  const history = useHistory();

  useEffect(() => {
    dispatch(
      changeInfoTela({
        title: "Obter Confiabilidade via Bancos Credenciados",
        breadcrumbs: [
          { label: "Nível da conta", link: "/" },
          {
            label: "Aumentar nível da conta",
            link: "/quero-este-nivel?nivel=prata"
          },
          { label: "Obter Confiabilidade via Bancos Credenciados" }
        ]
      })
    );
    let url = new URL(window.location.href);
    url.pathname = "/api" + url.pathname;

    fetchApi(url.toString(), { method: "GET" }).then(res => {
      changeLevelData({
        ...state.levelData,
        up: res?.hasLevelChanged,
        newReliability: res?.reliabilityId,
        level: res?.userLevel
      });

      if (res?.hasLevelChanged) {
        newReliabilityDispatch(persistNewReliability(res?.reliabilityId));
      }

      history.push("/");
    });
  }, [dispatch, newReliabilityDispatch]);

  return (
    <>
      <span className="page-title">
        Obter Confiabilidade via Bancos Credenciados
      </span>
      <div className="action-group">
        <div className="action-group-left">
          <Link className="action-tertiary" to="/quero-este-nivel?nivel=prata">
            Voltar
          </Link>
        </div>
      </div>
    </>
  );
}

export default AccreditedBanksRedirectEndpoint;
