export const NEW_RELIABILITY_ACQUIRED = "REL-STORE/NEW_RELIABILITY_ACQUIRED";
export const ERASE_PERSISTED_RELIABILITY =
  "REL-STORE/ERASE_PERSISTED_RELIABILITY";

export const persistNewReliability = newReliability => ({
  type: NEW_RELIABILITY_ACQUIRED,
  data: newReliability
});

export const eraseReliability = () => ({
  type: ERASE_PERSISTED_RELIABILITY,
  data: ""
});
