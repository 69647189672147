import { useEffect, useState, useMemo } from "react";

export function usePersistStringState(initialValue, id) {
  const _initialValue = useMemo(() => {
    const storageStringValue = sessionStorage.getItem("state:" + id);

    return storageStringValue ? storageStringValue : initialValue;
  }, [initialValue, id]);

  const [sessionState, setSessionState] = useState(_initialValue);

  useEffect(() => {
    sessionStorage.setItem("state:" + id, sessionState);
  }, [sessionState, id]);

  return [sessionState, setSessionState];
}
